<template>
	<b-tab :title="$t('Staff Access', 'Staff Access')" :active="activeTab === 'Staff Access'" @click="$emit('on-tab-change', 'Staff Access')">
		<staff-access-add-modal :staff="updateStaff" :show="is.showing.addModal" @close="closeAddModal" />
		<ktv-dialog
			v-model="is.showing.deleteDialog"
			:text="$t('Do you want to delete this data?', 'Do you want to delete this data?')"
			:title="$t('Delete Data', 'Delete Data')"
		>
			<template #footer>
				<ktv-button :text="$('Delete', 'Delete')" :loading="is.loading" @click="deleteData()" />
				<ktv-button :text="$('Cancel', 'Cancel')" color="light" :loading="is.loading" class="ml-2" @click="closeDeleteStaffDialog()" />
			</template>
		</ktv-dialog>
		<ktv-accordion name="staffAccessInformation" :title="$t('Staff Access Information', 'Staff Access Information')">
			<template #content>
				<div>
					<ktv-table
						id="staff-access"
						:columns="columns"
						:filter="false"
						:is-loading="is.loading"
						:rows="institutionStaffsData"
						:search-placeholder="$t('Search by staff name', 'Search by staff name')"
						:total-rows="institutionStaffsTotalRows"
						min-height="250px"
						mode="remote"
						@on-page-change="onPageChange"
						@on-per-page-change="onPerPageChange"
						@on-search="onSearch"
						@on-sort-change="onSortChange"
					>
						<template #actionleft>
							<ktv-button :text="$t('Add', 'Add')" icon="add" @click="showAddModal" />
						</template>
						<template #columns="{ props }">
							{{ $t(props.column.label, props.column.label) }}
						</template>
						<template #rows="{ props }">
							<span v-if="props.column.field === 'button'">
								<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
									<template #button-content>
										<img :src="icons.dropdown" />
									</template>
									<b-dropdown-item @click="showAddModal(props.row)">
										<img :src="icons.update" class="imgicon" />{{ $t("Update", "Update") }}
									</b-dropdown-item>
									<b-dropdown-item @click="showDeleteDialog(props.row)">
										<img :src="icons.delete" class="imgicon" />{{ $t("Delete", "Delete") }}
									</b-dropdown-item>
								</b-dropdown>
							</span>
						</template>
					</ktv-table>
				</div>
			</template>
		</ktv-accordion>
	</b-tab>
</template>

<script>
	import { KtvAccor as KtvAccordion, KtvButton, KtvDialog, KtvTable } from "@/components"
	import { mapActions, mapGetters } from "vuex"
	import StaffAccessAddModal from "./modals/_StaffAccessAdd"

	import deleteIcon from "@/assets/images/icon/icondelete.png"
	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import updateIcon from "@/assets/images/icon/iconedit.png"

	const icons = {
		delete: deleteIcon,
		dropdown: dropdownIcon,
		update: updateIcon,
	}

	export default {
		name: "LoanInstitutionStaffAccess",
		metaInfo: {
			title: "View Institution - Staff Access",
		},
		components: { KtvAccordion, KtvButton, KtvDialog, KtvTable, StaffAccessAddModal },
		props: {
			activeTab: {
				default: null,
				required: true,
				type: String,
			},
		},
		emits: ["on-tab-change"],
		data() {
			return {
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						field: "loanInstitutionStaffId",
						label: "ID",
						thClass: "text-left",
					},
					{
						field: "personName",
						label: "Staff Name",
						thClass: "text-left",
					},
					{
						field: "organisationRoleDesc",
						label: "Organisation Role",
						thClass: "text-left",
					},
				],
				deleteStaffId: null,
				icons: icons,
				is: {
					loading: false,
					showing: {
						addModal: false,
						deleteDialog: false,
					},
				},
				params: {
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
				updateStaff: null,
			}
		},
		computed: {
			...mapGetters({
				institutionStaffsData: "INSTITUTION/institutionStaffsData",
				institutionStaffsTotalRows: "INSTITUTION/institutionStaffsTotalRows",
			}),
		},
		mounted() {
			this.getData()
		},
		methods: {
			...mapActions({
				deleteInstitutionStaff: "INSTITUTION/deleteInstitutionStaff",
				getInstitutionStaffs: "INSTITUTION/getInstitutionStaffs",
			}),
			closeAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: false,
				}
			},
			closeDeleteStaffDialog() {
				this.deleteStaffId = null

				this.is.showing = {
					...this.is.showing,
					deleteDialog: false,
				}

				this.is = {
					...this.is,
					loading: false,
				}
			},
			deleteData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.deleteInstitutionStaff(this.deleteStaffId)
					.then(() => {
						this.closeDeleteStaffDialog()
					})
					.catch(() => {
						this.closeDeleteStaffDialog()
					})
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getInstitutionStaffs({
					loanInstitutionId: this.$route.params.id,
					params: this.params,
				})
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: currentPage })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSearch(searchTerm) {
				this.updateParams({ search: `personName=${searchTerm}` })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			showAddModal(staff = null) {
				if (staff) {
					this.updateStaff = staff
				}

				this.is.showing = {
					...this.is.showing,
					addModal: true,
				}
			},
			showDeleteDialog({ loanInstitutionStaffId }) {
				this.deleteStaffId = loanInstitutionStaffId

				this.is.showing = {
					...this.is.showing,
					deleteDialog: true,
				}
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
