_
<template>
	<b-tab :title="$t('Loan Package', 'Loan Package')" :active="activeTab === 'Loan Package'" @click="$emit('on-tab-change', 'Loan Package')">
		<b-form>
			<ktv-table
				id="loan-package"
				:columns="columns"
				:filter="false"
				:is-loading="is.loading"
				:rows="institutionPackagesData"
				:search-custom-enabled="false"
				:search-placeholder="$t('Search by package name', 'Search by package name')"
				:total-rows="institutionPackagesTotalRows"
				min-height="250px"
				mode="remote"
				@on-page-change="onPageChange"
				@on-per-page-change="onPerPageChange"
				@on-search="onSearch"
				@on-sort-change="onSortChange"
			>
				<template #actionleft>
					<router-link v-if="isAllowedToAdd" :to="{ name: 'Loan Package Add', params: { institutionId: $route.params.id } }">
						<ktv-button :text="$t('Add', 'Add')" icon="add" />
					</router-link>
				</template>
				<template #columns="{ props }">
					{{ $t(props.column.label, props.column.label) }}
				</template>
				<template #rows="{ props }">
					<span v-if="props.column.field === 'button'">
						<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
							<template #button-content>
								<img :src="icons.dropdown" />
							</template>
							<b-dropdown-item @click="showDetail(props.row)">
								<img :src="icons.view" class="imgicon" />{{ $t("View", "View") }}
							</b-dropdown-item>
							<b-dropdown-item @click="showUpdate(props.row)">
								<img :src="icons.update" class="imgicon" />{{ $t("Update", "Update") }}
							</b-dropdown-item>
						</b-dropdown>
					</span>
					<span v-else-if="props.column.field === 'currencyIsoCode'">{{ props.row.currencyIsoCode }} - {{ props.row.currencyName }}</span>
					<span v-else-if="props.column.field === 'totalCashAmount'">{{ props.row.totalCashAmount | Number }}</span>
					<span v-else-if="props.column.field === 'totalProductAmount'">{{ props.row.totalProductAmount | Number }}</span>
				</template>
			</ktv-table>
		</b-form>
	</b-tab>
</template>

<script>
	import { findWhere } from "underscore"
	import { KtvButton, KtvTable } from "@/components"
	import { mapActions, mapGetters } from "vuex"

	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import updateIcon from "@/assets/images/icon/iconedit.png"
	import viewIcon from "@/assets/images/icon/iconview.png"

	const icons = {
		dropdown: dropdownIcon,
		update: updateIcon,
		view: viewIcon,
	}

	export default {
		name: "LoanInstitutionPackage",
		metaInfo: {
			title: "View Institution - Loan Package",
		},
		components: { KtvButton, KtvTable },
		props: {
			activeTab: {
				default: null,
				required: true,
				type: String,
			},
		},
		emits: ["on-tab-change"],
		data() {
			return {
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "loanPackageId",
						label: "ID",
						thClass: "text-left",
						width: "75px",
					},
					{
						field: "packageName",
						label: "Package Name",
						thClass: "text-left",
						width: "250px",
					},
					{
						field: "loanPackageTypeDesc",
						label: "Package Type",
						thClass: "text-center",
						tdClass: "text-center",
						width: "200px",
					},
					{
						field: "currencyIsoCode",
						label: "Currency",
						thClass: "text-center",
						tdClass: "text-center",
						width: "250px",
					},
					{
						field: "totalCashAmount",
						label: "Cash Amount",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "totalProductAmount",
						label: "Product Amount",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "count",
						label: "Product",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "kioskName",
						label: "Kiosk",
						tdClass: "text-center",
						thClass: "text-center",
						width: "250px",
					},
				],
				icons: icons,
				is: {
					loading: false,
				},
				params: {
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
			}
		},
		computed: {
			...mapGetters({
				institutionPackagesData: "INSTITUTION/institutionPackagesData",
				institutionPackagesTotalRows: "INSTITUTION/institutionPackagesTotalRows",
				loanInstitutionActions: "AUTHDATA/loanInstitutionActions",
			}),
			isAllowedToAdd() {
				return this.getActionPermission("add_package")
			},
		},
		created() {
			this.getData()
		},
		methods: {
			...mapActions({
				getInstitutionPackages: "INSTITUTION/getInstitutionPackages",
			}),
			getActionPermission(name = null) {
				return findWhere(this.loanInstitutionActions, { function: name })
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getInstitutionPackages({
					institutionId: this.$route.params.id,
					params: this.params,
				})
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: currentPage })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSearch(searchTerm) {
				this.updateParams({ search: `packageName=${searchTerm}` })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			showDetail({ loanPackageId }) {
				this.$router.push({ name: "Loan Package Data", params: { institutionId: this.$route.params.id, id: loanPackageId } })
			},
			showUpdate({ loanPackageId }) {
				this.$router.push({ name: "Loan Package Update", params: { institutionId: this.$route.params.id, id: loanPackageId } })
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
