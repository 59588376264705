<template>
	<b-tab :title="$t('Basic Data', 'Basic Data')" :active="activeTab === 'Basic Data'" @click="$emit('on-tab-change', 'Basic Data')">
		<b-row>
			<b-col>
				<ktv-accordion name="institutionInformation" :title="$t('Institution Information', 'Institution Information')">
					<template #content>
						<div v-if="localInstitution">
							<b-row>
								<ktv-input
									v-model="localInstitution.loanInstitutionId"
									:label="$t('Institution ID', 'Institution ID')"
									:readonly="true"
									placeholder="-"
								/>
							</b-row>
							<b-row>
								<ktv-input
									v-model="localInstitution.createdDate"
									:label="$t('Date of Data Collection', 'Date of Data Collection')"
									:readonly="true"
									placeholder="-"
									type="date"
								/>
							</b-row>
							<b-row>
								<ktv-input
									v-model="localInstitution.institutionName"
									:label="$t('Institution Name', 'Institution Name')"
									:readonly="true"
									placeholder="-"
								/>
							</b-row>
							<b-row v-if="localInstitution.parentId">
								<ktv-input
									v-model="localInstitution.parentDesc"
									:label="$t('Parent Institution', 'Parent Institution')"
									:readonly="true"
									placeholder="-"
								/>
							</b-row>
							<b-row>
								<ktv-input
									v-model="localInstitution.legalStatusDesc"
									:label="$t('Legal Status', 'Legal Status')"
									:readonly="true"
									placeholder="-"
								/>
							</b-row>
							<b-row>
								<ktv-input
									v-model="localInstitution.yearEstablished"
									:label="$t('Established Year', 'Established Year')"
									:readonly="true"
									placeholder="-"
								/>
							</b-row>
							<b-row>
								<ktv-select
									v-model="localInstitution.countryId"
									:label="$t('Country', 'Country')"
									:options="countryOptions"
									disabled
									readonly
								/>
							</b-row>
							<b-row>
								<ktv-input v-model="localInstitution.provinceDesc" placeholder="-" :readonly="true" :label="$t('Province', 'Province')" />
							</b-row>
							<b-row>
								<ktv-input v-model="localInstitution.districtDesc" placeholder="-" :readonly="true" :label="$t('District', 'District')" />
							</b-row>
							<b-row>
								<ktv-input
									v-model="localInstitution.subDistrictDesc"
									:label="$t('Sub District', 'Sub District')"
									:readonly="true"
									placeholder="-"
								/>
							</b-row>
							<b-row>
								<ktv-input v-model="localInstitution.villageDesc" placeholder="-" :readonly="true" :label="$t('Village', 'Village')" />
							</b-row>
							<b-row>
								<ktv-textarea
									v-model="localInstitution.address"
									:label="$t('Address', 'Address')"
									:max-rows="6"
									:readonly="true"
									:rows="3"
									placeholder="-"
								/>
							</b-row>
							<b-row>
								<ktv-input v-model="localInstitution.npwp" placeholder="-" :readonly="true" :label="$t('NPWP', 'NPWP')" />
							</b-row>
						</div>
					</template>
				</ktv-accordion>
			</b-col>
			<b-col>
				<ktv-accordion name="communicationAndMedia" :title="$t('Communication and Media', 'Communication and Media')">
					<template #content>
						<div>
							<b-row>
								<b-form-group class="col-md-12 mb-2" :label="$t('Photo', 'Photo')">
									<b-row align-h="center" class="px-3">
										<b-img v-if="localInstitution.photo" :src="localInstitution.photo" fluid thumbnail :alt="$t('Photo', 'Photo')" />
										<b-img v-else :src="placeholders.photo" fluid thumbnail :alt="$t('Photo', 'Photo')" />
									</b-row>
								</b-form-group>
							</b-row>
							<b-row>
								<b-form-group class="col-md-12 mb-2" :label="$t('Logo', 'Logo')">
									<b-row align-h="center" class="px-3">
										<b-img
											v-if="localInstitution.companyLogo"
											:label="$t('Logo', 'Logo')"
											:src="localInstitution.companyLogo"
											fluid
											thumbnail
										/>
										<b-img v-else :src="placeholders.companyLogo" fluid thumbnail :label="$t('Logo', 'Logo')" />
									</b-row>
								</b-form-group>
							</b-row>
							<b-row>
								<ktv-input v-model="localInstitution.email" placeholder="-" :readonly="true" :label="$t('Email', 'Email')" />
							</b-row>
							<b-row>
								<ktv-input
									v-model="localInstitution.phoneNumber"
									:label="$t('Telephone Number', 'Telephone Number')"
									:readonly="true"
									placeholder="-"
								/>
							</b-row>
						</div>
						<ktv-accordion
							:title="$t('Institution Representative Contact', 'Institution Representative Contact')"
							name="institutionRepresentativeContact"
						>
							<template #content>
								<div>
									<b-row>
										<b-form-group class="col-md-12 mb-2" :label="$t('Photo', 'Photo')">
											<b-row align-h="center">
												<b-img
													v-if="localInstitution.institutionStaffList.photo"
													:alt="$t('Photo', 'Photo')"
													:src="localInstitution.institutionStaffList.photo"
													fluid
													thumbnail
												/>
												<b-img v-else :src="placeholders.representative.photo" fluid thumbnail :alt="$t('Photo', 'Photo')" />
											</b-row>
										</b-form-group>
									</b-row>
									<b-row>
										<ktv-input
											v-model="localInstitution.institutionStaffList.personName"
											:label="$t('Name', 'Name')"
											:readonly="true"
											placeholder="-"
										/>
									</b-row>
									<b-row>
										<ktv-input
											v-model="localInstitution.institutionStaffList.organisationRoleDesc"
											:label="$t('Position', 'Position')"
											:readonly="true"
											placeholder="-"
										/>
									</b-row>
									<b-row>
										<ktv-input
											v-model="localInstitution.institutionStaffList.birthDate"
											:label="$t('Birth Date', 'Birth Date')"
											:readonly="true"
											placeholder="-"
										/>
									</b-row>
									<b-row>
										<b-form-group v-slot="{ ariaDescribedby }" class="col-md-12 mb-2" :label="$t('Gender', 'Gender')">
											<b-form-radio-group
												id="radio-group-gender"
												v-model="localInstitution.institutionStaffList.gender"
												:aria-describedby="ariaDescribedby"
												:options="genderOptions"
												name="radio-gender"
												readonly
											/>
										</b-form-group>
									</b-row>
									<b-row>
										<ktv-input
											v-model="localInstitution.institutionStaffList.useremail"
											:label="$t('Email', 'Email')"
											:readonly="true"
											placeholder="-"
										/>
									</b-row>
									<b-row>
										<ktv-input
											v-model="localInstitution.institutionStaffList.phoneNumber"
											:label="$t('Phone Number', 'Phone Number')"
											:readonly="true"
											placeholder="-"
										/>
									</b-row>
								</div>
							</template>
						</ktv-accordion>
					</template>
				</ktv-accordion>
			</b-col>
		</b-row>
	</b-tab>
</template>

<script>
	import { KtvAccor as KtvAccordion, KtvInput, KtvSelect, KtvTextarea } from "@/components"
	import { map } from "underscore"
	import { mapActions, mapState } from "vuex"

	import PlaceholderPhoto from "@/assets/images/placeholders/partner-logo.png"
	import PlaceholderStaffPhoto from "@/assets/images/placeholders/representative-photo.png"

	export default {
		name: "LoanInstitutionBasicData",
		metaInfo: {
			title: "View Institution - Basic Data",
		},
		components: { KtvAccordion, KtvInput, KtvSelect, KtvTextarea },
		props: {
			activeTab: {
				default: null,
				required: true,
				type: String,
			},
			institution: {
				default: () => {},
				required: true,
				type: Object,
			},
		},
		emits: ["on-tab-change"],
		data() {
			return {
				countryOptions: [],
				genderOptions: [],
				localInstitution: null,
				placeholders: {
					companyLogo: PlaceholderPhoto,
					photo: PlaceholderPhoto,
					representative: {
						photo: PlaceholderStaffPhoto,
					},
				},
				representative: {
					dateOfBirth: null,
					email: null,
					gender: 0,
					name: null,
					phoneNumber: null,
					photo: null,
					position: null,
				},
			}
		},
		computed: {
			...mapState("OTHERS", ["countryList", "genderList"]),
		},
		watch: {
			countryList: {
				deep: true,
				handler() {
					this.getCountryOptions()
				},
				immediate: true,
			},
			genderList: {
				deep: true,
				handler() {
					this.getGenderOptions()
				},
				immediate: true,
			},
			institution: {
				deep: true,
				handler(institution) {
					this.localInstitution = Object.assign({}, institution)
				},
				immediate: true,
			},
		},
		created() {
			this.getCountryList()
			this.getGenderList()
		},
		methods: {
			...mapActions({
				getCountryList: "OTHERS/getCountryList",
				getGenderList: "OTHERS/getGenderList",
			}),
			getCountryOptions() {
				this.countryOptions = map(this.countryList, (country) => {
					return {
						text: country.country,
						value: parseInt(country.entityCountryID, 10),
					}
				})
			},
			getGenderOptions() {
				this.genderOptions = map(this.genderList, (gender) => {
					return {
						text: gender.value.charAt(0).toUpperCase() + gender.value.slice(1),
						value: parseInt(gender.entityListOfValueId, 10),
					}
				})
			},
		},
	}
</script>
