<template>
	<div class="main-content">
		<ktv-modal
			v-model="is.showingModal"
			:title="$t('Staff Access Information', 'Staff Access Information')"
			no-close-on-backdrop
			no-close-on-esc
			@close="close()"
		>
			<template #content>
				<validation-observer ref="form_add_staff" tag="form" @submit.prevent="save">
					<ktv-input
						v-if="staff && staff.loanInstitutionStaffId"
						v-model="localStaff.loanInstitutionStaffId"
						:label="$t('Staff ID', 'Staff ID')"
						:readonly="true"
					/>
					<ktv-input v-model="localStaff.personName" rules="required" :label="$t('Staff Name', 'Staff Name')" />
					<ktv-input v-model="localStaff.birthDate" :label="$t('Birth Date', 'Birth Date')" :max="maxDate" :min="minDate" type="date" />
					<ktv-input v-model="localStaff.birthPlace" :label="$t('Birth Place', 'Birth Place')" />
					<ktv-select
						v-model="localStaff.organisationRole"
						:label="$t('Organisation Role', 'Organisation Role')"
						:options="organisationRoleOptions"
					/>
				</validation-observer>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Save', 'Save')" :disabled="is.loading" :loading="is.loading" icon="save" @click="save()" />
					<ktv-button :text="$t('Close', 'Close')" color="light" :loading="is.loading" class="ml-2" @click="close()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvButton, KtvInput, KtvModal, KtvSelect } from "@/components"
	import { map } from "underscore"
	import { mapActions, mapGetters, mapState } from "vuex"
	import { ValidationObserver } from "vee-validate"
	import moment from "moment"

	export default {
		name: "LoanInstitutionStaffAccessAdd",
		metaInfo: {
			title: "View Institution - Staff Access - Add",
		},
		components: { KtvButton, KtvInput, KtvModal, KtvSelect, ValidationObserver },
		props: {
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
			staff: {
				default: () => {},
				required: false,
				type: Object,
			},
		},
		emits: ["close"],
		data() {
			return {
				is: {
					loading: false,
					showingModal: false,
				},
				localStaff: {
					birthDate: null,
					birthPlace: null,
					loanInstitutionStaffId: null,
					organisationRole: null,
					personName: null,
				},
				organisationRoleOptions: [],
			}
		},
		computed: {
			...mapGetters({
				userId: "AUTHDATA/userId",
			}),
			...mapState("OTHERS", ["organisationRoleList"]),
			maxDate() {
				const d = new Date()
				const year = d.getFullYear()
				const month = d.getMonth()
				const day = d.getDate()
				let maxDate = new Date(year - 17, month, day)

				const offset = maxDate.getTimezoneOffset()
				maxDate = new Date(maxDate.getTime() - offset * 60 * 1000)

				return moment(maxDate).format("yyyy-MM-DD")
			},
			minDate() {
				const d = new Date()
				const year = d.getFullYear()
				const month = d.getMonth()
				const day = d.getDate()
				let minDate = new Date(year - 100, month, day)

				const offset = minDate.getTimezoneOffset()
				minDate = new Date(minDate.getTime() - offset * 60 * 1000)

				return moment(minDate).format("yyyy-MM-DD")
			},
		},
		watch: {
			organisationRoleList: {
				deep: true,
				handler() {
					this.getOrganisationRoleOptions()
				},
				immediate: true,
			},
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
			staff: {
				deep: true,
				handler(staff) {
					if (staff && staff.loanInstitutionStaffId) {
						this.localStaff = Object.assign({}, staff)
					} else {
						this.localStaff = Object.assign(
							{},
							{
								birthDate: null,
								birthPlace: null,
								loanInstitutionStaffId: null,
								organisationRole: null,
								personName: null,
							},
						)
					}
				},
				immediate: true,
			},
		},
		created() {
			this.getOrganisationRoleList()
		},
		methods: {
			...mapActions({
				createInstitutionStaff: "INSTITUTION/createInstitutionStaff",
				getOrganisationRoleList: "OTHERS/getOrganisationRoleList",
				updateInstitutionStaff: "INSTITUTION/updateInstitutionStaff",
			}),
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.localStaff = {
					birthDate: null,
					birthPlace: null,
					loanInstitutionStaffId: null,
					organisationRole: null,
					personName: null,
				}

				this.$emit("close")
			},
			getOrganisationRoleOptions() {
				this.organisationRoleOptions = map(this.organisationRoleList, (organisationRole) => {
					return {
						text: organisationRole.value,
						value: organisationRole.entityListOfValueId,
					}
				})
			},
			save() {
				const self = this
				this.is = {
					...this.is,
					loading: true,
				}

				this.$refs.form_add_staff.validate().then((valid) => {
					if (valid) {
						if (self.staff?.loanInstitutionStaffId) {
							self.updateInstitutionStaff(self.localStaff)
								.then(() => {
									self.close()
								})
								.catch(() => {
									self.close()
								})
						} else {
							self.createInstitutionStaff({
								...self.localStaff,
								createdBy: parseInt(self.userId, 10),
								loanInstitutionId: parseInt(self.$route.params.id, 10),
							})
								.then(() => {
									self.close()
								})
								.catch(() => {
									self.close()
								})
						}
					} else {
						self.is = {
							...self.is,
							loading: false,
						}
					}
				})
			},
		},
	}
</script>
