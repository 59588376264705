<template>
	<div class="main-content">
		<ktv-breadcrumb :title="$t('View Institution', 'View Institution')" :page="$t('Institution', 'Institution')" :folder="$t('Loan', 'Loan')" />
		<b-row>
			<b-col md="12 mb-30">
				<b-row>
					<b-col>
						<h1>{{ institutionData && institutionData.institutionName ? institutionData.institutionName : "-" }}</h1>
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col>
						<router-link v-b-tooltip.hover :to="{ name: 'Loan Institution List' }">
							<ktv-back :label="$t('Back to institution list', 'Back to institution list')" />
						</router-link>
					</b-col>
				</b-row>
				<div>
					<b-card
						:header="$t('Institution Basic Data', 'Institution Basic Data')"
						align="left"
						border-variant="danger"
						header-bg-variant="primary"
						header-text-variant="white"
					>
						<b-alert v-if="is.loading" variant="primary" show> Loading... </b-alert>
						<b-tabs v-if="!is.loading" active-nav-item-class="nav nav-tabs">
							<basic-data :active-tab="activeTab" :institution="institutionData" @on-tab-change="($event) => (activeTab = $event)" />
							<staff-access :active-tab="activeTab" @on-tab-change="($event) => (activeTab = $event)" />
							<loan-package v-if="isAllowedToViewPackages" :active-tab="activeTab" @on-tab-change="($event) => (activeTab = $event)" />
						</b-tabs>
					</b-card>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import { findWhere } from "underscore"
	import { KtvBack } from "@/components"
	import { mapActions, mapGetters } from "vuex"
	import BasicData from "./_BasicData"
	import LoanPackage from "./_LoanPackage"
	import StaffAccess from "./_StaffAccess"

	export default {
		name: "LoanInstitution",
		metaInfo: {
			title: "View Institution",
		},
		components: { BasicData, KtvBack, LoanPackage, StaffAccess },
		data() {
			return {
				activeTab: "Basic Data",
				is: {
					loading: false,
				},
			}
		},
		computed: {
			...mapGetters({
				institutionData: "INSTITUTION/institutionData",
				loanInstitutionActions: "AUTHDATA/loanInstitutionActions",
			}),
			isAllowedToViewPackages() {
				return this.getActionPermission("view_packages")
			},
		},
		created() {
			this.getData()
		},
		beforeDestroy() {
			this.resetInstitution()
		},
		methods: {
			...mapActions({
				getInstitution: "INSTITUTION/getInstitution",
				resetInstitution: "INSTITUTION/resetInstitution",
			}),
			getActionPermission(name = null) {
				return findWhere(this.loanInstitutionActions, { function: name })
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getInstitution(this.$route.params.id).then(() => {
					this.is = {
						...this.is,
						loading: false,
					}
				})
			},
		},
	}
</script>
